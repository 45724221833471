import {useNavigate} from "react-router-dom";

const EVENTS = {
    GOT_ACCESS_CODE : 'GOT_ACCESS_CODE',
    GOT_GIFT_INFO : 'GOT_GIFT_INFO',
    CLICKED_ADD_MESSAGE: 'CLICKED_ADD_MESSAGE',
    CLICKED_PREVIEW: 'CLICKED_PREVIEW',
    EMAIL_CHANGE: 'EMAIL_CHANGE',
    TEXT_MSG_CHANGE: 'TEXT_MSG_CHANGE',
    UPLOAD_START : 'UPLOAD_START',
    UPLOAD_SUCCESS : 'UPLOAD_SUCCESS',
    RESET: 'RESET',
    ACCEPT: 'ACCEPT'
}

const Reducer = (state, action) => {
    console.log("--- Reducer ---", state, action)

    if(!action) {
        throw new Error("No action provided to Reducer")
    }
    switch (action.type) {
        case EVENTS.RESET:
            return {
                ...state,
                addMessageButtonClicked: false,
                previewButtonClicked: false
            }
        case EVENTS.ACCEPT:
            return {
                ...state,
                acceptButtonClicked: true
            }
        case EVENTS.GOT_ACCESS_CODE:
            return {
                ...state,
                accessCode: action.payload
            };
        case EVENTS.GOT_GIFT_INFO:
            console.log("Reducer GOT_GIFT_INFO:", action)
            return {
                ...state,
                beneficiary: action.payload.beneficiary,
                sender: action.payload.sender,
                videoUrl: action.payload.videoUrl,
                textMsg: action.payload.textMsg
            }
        case EVENTS.EMAIL_CHANGE:
            console.log("Reducer EMAIL_CHANGE", action)
            return {
                ...state,
                beneficiary: {
                    ...state.beneficiary,
                    email: action.payload
                }
            }
        case EVENTS.TEXT_MSG_CHANGE:
            console.log("Reducer TEXT_MSG_CHANGE", action)
            return {
                ...state,
                textMsg: action.payload
            }
        case EVENTS.CLICKED_ADD_MESSAGE:
            return {
                ...state,
                addMessageButtonClicked: true
            }
        case EVENTS.CLICKED_PREVIEW:
            return {
                ...state,
                previewButtonClicked: true
            }
        case EVENTS.UPLOAD_START:
            return {
                ...state
            };
        case EVENTS.UPLOAD_SUCCESS:
            return {
                ...state,
                uploadSuccessful:true
            };
        default:
            console.warn("unknown action:" + JSON.stringify(action))
            return {
                ...state
            }
    }
};

export {EVENTS};
export default Reducer

