import React, {useEffect} from "react";
import {useGiftCardContext} from "../store";
import {EVENTS} from "../reducer";
import exampleQR from "../../QR-codes/localhost_3000_12345678.png"
import {getCodeFromQueryString, loadGiftInfo} from "../utils/backendUtils";

const BrandedGiftCard = () => {
    return <div className="brandedGiftCard"></div>
}

const Home = ({onClickButton}) => {
    const [state, dispatch] = useGiftCardContext()

    useEffect( () => {
        const accessCode = getCodeFromQueryString(window.location.search)
        if(accessCode){
            const event = {type:EVENTS.GOT_ACCESS_CODE, payload:accessCode}
            console.log("dispatching ", event)
            dispatch(event)
        }
        const storeGiftInfo = async(code) => {
            const event = await loadGiftInfo(code);
            dispatch(event)
        }
        if(state.accessCode) {
            storeGiftInfo(state.accessCode)
        }
    },[state.accessCode])

    if(!state || !state.accessCode) {
        const randomCode = String(Math.random()*100000000).slice(0,8).padStart(8,'0')
        return <div className={"contents"}>Missing access code, scan your QR
            <a href={"/?code="+randomCode}><img src={exampleQR}/></a>
        </div>
    } else {
        return <>
            <div className={"contents"}>

                {
                    !state.beneficiary ?
                        <><h1>Hello!</h1><p>Loading the details of the gift</p></> :
                        <><p>Here's your gift for <b>{state.beneficiary.name}</b>. Why not add an extra touch!</p>
                            <BrandedGiftCard/>
                        </>
                }

            </div>
            <div className={"navibar"}>
                <button className="aux" data-btn="cancel" onClick={onClickButton}>cancel</button>
                <button className="main" data-btn="addMessage" onClick={onClickButton}>add message</button>
            </div>
        </>
    }

}

export default Home
