import {useGiftCardContext} from "./store";
import React, {useEffect} from "react";
import {getCodeFromQueryString, loadGiftInfo} from "./utils/backendUtils";
import {EVENTS} from "./reducer";
import VideoMessage from "./video-message";

const MessagePlayer = () => {
    return <MessagePlayerContents/>
}
const MessagePlayerContents = () => {
    const [state, dispatch] = useGiftCardContext()
    const search = window.location.search;

    useEffect( () => {
        const accessCode = getCodeFromQueryString(search)
        if(accessCode){
            const event = {type:EVENTS.GOT_ACCESS_CODE, payload:accessCode}
            console.log("dispatching ", event)
            dispatch(event)
        }
    },[])

    useEffect( () => {
        const storeGiftInfo = async(code) => {
            const event = await loadGiftInfo(code);
            setTimeout(()=>dispatch(event), 2000)
        }
        if(state.accessCode) {
            storeGiftInfo(state.accessCode)
        }
    },[state.accessCode])

    console.log("MessagePlayer", state)
    const hasLoaded = state && state.accessCode && state.beneficiary
    return <>
        <div className={`contents ${hasLoaded ? "hide" : "show"}`}><p> Hey, looks like you have a message! Bear with us while we load it...</p></div>
        <div className={`contents ${hasLoaded ? "show" : "none"}`}>
            <VideoMessage sender={state.sender.name} beneficiary={state.beneficiary.name} videoUrl={state.videoUrl}
                          textMsg={state.textMsg}/>
            </div>

        </>
}

export default MessagePlayer