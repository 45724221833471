import React, {createContext, useContext, useEffect, useReducer} from "react";
import {matchRoute} from "./utils/routerUtils";

const initialState = {
    route:{}
};

const RouterContext = createContext(initialState);

const RouterProvider = ( {children} ) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <RouterContext.Provider value={[ state, dispatch ]}>
            {children}
        </RouterContext.Provider>
    );
}

const useRouterContext = () => {
    let stateAndDispatch = useContext(RouterContext)
    const dispatchFn = (...args) => {
        console.log("dispatchFn", ...args)
    }
    if (! (Symbol.iterator in Object(stateAndDispatch))) {
        stateAndDispatch = [stateAndDispatch, dispatchFn]
    }
    return stateAndDispatch
}

const Reducer = (state, action) => {
    if(state && action.payload.requestPath != state.route.requestPath)
        return {
            ...state,
            route: action.payload
        }
    else return {...state}
}
const Router = ({children}) =>{
    const [state, dispatch] = useRouterContext();
    let route;
    useEffect( () => {
        route = matchRoute(children, window.location.pathname);
        dispatch({type:"route", payload:route});
    },[])

    if(state.route) {
        return state.route.children;
    } else {
        return <div>page not found, sorry</div>
    }
}

const Route = ({children, path}) =>{
    return children;
}

export default Router
export {Route, RouterContext, RouterProvider, Reducer, useRouterContext}