import React, {useContext, useEffect} from "react";
import API from "../api";
import {EVENTS} from "../reducer";
import {useGiftCardContext} from "../store";


const Accepted = ({onClickButton}) => {

    const [state, dispatch] = useGiftCardContext()
    const api = API()
    console.log("Accepted: ", state)

    if(state.accessCode && state.acceptButtonClicked) {
        const giftInfoUpdate = {
            beneficiary: {
                email: state.beneficiary.email
            },
            textMsg: state.textMsg
        }
        const response = api.scheduleEmailToBeneficiary(state.accessCode, giftInfoUpdate)
    }

    return state && state.beneficiary ?
        <>
            <div className={"contents"}>
                <p>Your message for <b>{state.beneficiary.name}</b> has been scheduled. Thank you!</p>
            </div>
            <div className={"navibar"}>

            </div>
        </> : <p>missing data</p>
}
export default Accepted;
