import ReactPlayer from "react-player";
import React from "react";


const VideoMessage = ({beneficiary, sender, videoUrl, textMsg}) => {
    console.log("VideoMessage ", beneficiary, sender, videoUrl, textMsg)
    return (<>
            <p>Hey {beneficiary}!<br/>Check what <b>{sender}</b> sent you:<br/><br/></p>
            <div>
                {videoUrl ? <div className="video">
                    <ReactPlayer url={videoUrl} width="100%"  controls></ReactPlayer>
                </div> : null}
                {textMsg ? <h3>{textMsg}</h3> : null}
                {!(videoUrl || textMsg) ? <p>Hope you'll enjoy your gift!</p> : null}
            </div>
        </>);
}

export default VideoMessage