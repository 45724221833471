import React, {forwardRef, useState} from "react";
import { useTranslation } from "react-i18next";
import TypewriterComponent from "typewriter-effect";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const LoadingSpinner = () => {
  return <div className="loader spinner"></div>
}
const CardArtwork = forwardRef((props, ref) => {
    return <div className="cardArtwork" ref={ref}>
      <LoadingSpinner/>
    </div>
});


const BrandHeader = forwardRef((props, ref) => {
  return <div className={"brandHeader"} ref={ref}>
    <div className="logo"></div>
  </div>
});

const InfoPanel = forwardRef(({className='', text}, ref) => {
  return <div className={`infoPanel ${className}`} ref={ref}>
    <TypewriterComponent options={{strings:[text], loop:true, autoStart:true, delay:20, pauseFor:90000}}/>
  </div>
});

const VideoPanel = forwardRef(({videoUrl, className=''}, ref) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const handleClick = () => {
    setIsPlaying(!isPlaying);
  }
  const handleOnReady = () => {
    setIsLoading(false);
  }
  return <div onClick={handleClick} className={isLoading ? "loading" : "ready"}>
    <div className={"controls"}>
      {isPlaying ?
        <FontAwesomeIcon icon={faPause} /> :
        <FontAwesomeIcon icon={faPlay} />
      }
    </div>
    <LoadingSpinner/>
    <ReactPlayer url={videoUrl} width="100%" height="auto"
                 ref={ref} className={`videoPlayer ${className}`}
                 onReady={handleOnReady}
                 playing={isPlaying}/>
  </div>
});

const VideoWrapper = forwardRef(({className='', children}, ref) => {
  return <div className={`videoWrapper ${className}`}>
    <div className="progress" ref={ref}></div>
    {children}
  </div>
});


const trx2TableRow = (trx, i) => {
  return <div className={`trx ${trx.statusDescription}`} key={i}>
      <span className={"trxDate"}>{trx.transactionDate}</span>
      <span className={"trxTime"}>{trx.transactionTime}</span>
      <span className={"trxId"}>{trx.transactionId}</span>
      <span className={"trxAmount"}>{Number.parseFloat(trx.billAmount).toFixed(2)} {trx.currencyCode}</span>
      <span className={"trxMerchant"}>{trx.merchantName.replaceAll('\\',' ')}</span>
    </div>
}

const Statement = forwardRef(({className, transactions}, ref) => {
  const {t, i18next} = useTranslation();
  const hasTransactions = (transactions && transactions.length > 0);
  return <div className={`transactionList ${className}`} ref={ref}>
    <h2>{t('giftcard.transactionList')}</h2>
    {hasTransactions ? transactions.map(trx2TableRow) : t('giftcard.noTransactionsYet')}
  </div>

});


const LanguageSwitch = ({i18next}) => {
  //const {t, i18next} = useTranslation();
  const langs = ['en','es','pl'];
  const changeLanguageHandler = async (e) => {
     const lang = e.currentTarget.dataset.lang;
     await i18next.changeLanguage(lang);
     window.location.reload();
   }

   return <div className="languageSwitch">
     {langs.map((lang, i) => <span data-lang={lang} key={i} onClick={changeLanguageHandler}>{lang}</span>)}
   </div>
}

export {VideoPanel, VideoWrapper, InfoPanel, BrandHeader, CardArtwork, Statement, LoadingSpinner, LanguageSwitch}