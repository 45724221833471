import React, {useEffect} from "react";
import {useGiftCardContext} from "../store";
import VideoMessage from "../video-message";


const PreviewMessage = ({onClickButton}) => {

    const [state, dispatch] = useGiftCardContext()

    let contents =

        (state.beneficiary) ?
        <>
            <p>Here is what <b>{state.beneficiary.name}</b> will see:</p>
            <div className="preview">
            <VideoMessage videoUrl={state.videoUrl}
                          beneficiary={state.beneficiary.name}
                          sender={state.sender.name}
                          textMsg={state.textMsg}/>
            </div>
        </> : <small>loading...</small>



    return <>
        <div className={"contents"}>
            {contents}
        </div>
        <div className={"navibar"}>
            <button className="aux" data-btn="backToAddMessage" onClick={onClickButton}>Try another one</button>
            <button className="main" data-btn="accept" onClick={onClickButton}>Accept & Send</button>
        </div>
    </>

}
export default PreviewMessage;