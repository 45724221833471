const matchRoute = (routes, path) => {
    for (let i in routes) {
        const route = routes[i];
        const match = {
            requestPath: path,
            children: route.props.children
        };
        if (path === route.props.path) return match;
        match.params = matchRouteWithParams(path, route);
        if (match.params) {
            return match;
        }
    }
    return false;
}

const matchRouteWithParams = (path, route) => {
    //console.log("getParams", path, route);
    let routePathParts = route.props.path.split("/");
    let pathParts = path.split("/");
    let params = {};

    if (routePathParts.length !== pathParts.length) return false;

    for (let i in routePathParts) {
        let part = routePathParts[i];
        if (part.charAt(0) === ':') {
            params[part.slice(1)] = pathParts[i];
        } else {
            if (part !== pathParts[i]) return false;
        }
    }
    return params;
}

export {matchRoute, matchRouteWithParams}