import "./progress-bar.css"
const ProgressBar = ({value, label=''}) => {
  const percentValue = `${value}%`

  return (value > 0) && (value < 100) ? <div className="progressBar">
    <div className="label">{label} {percentValue}</div>
    <div className="bar" style={{width : percentValue}}></div>
  </div> : null;
}

export default ProgressBar