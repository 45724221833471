import STATE_ENGINE, {ANY} from "../utils/screen-state-engine";

const STATE = STATE_ENGINE({
  brandHeader: ["large", "small"],
  infoPanel: ["default", "collapsed", "full", "error"],
  cardArtwork: ["default", "loading", "hidden"],
  videoPanel: ["collapsed", "expanded"],
  videoWrapper: ["collapsed", "expanded", "uploading", "error"],
  buttonGroup: ["hidden", "shown"],
  transactionList: ["hidden", "shown"],
  buttonAddVideoMessage: ["hidden", "shown"],
  buttonSelectAnotherVideo: ["hidden", "shown"],
  buttonDeleteVideo: ["hidden", "shown"],
  buttonAbortVideoUpload: ["hidden", "shown"],
  buttonAddVideo: ["hidden", "shown"],
  buttonListTrxs: ["hidden", "shown"],
  buttonConfirm: ["hidden", "shown"],
  buttonBackToHome: ["hidden", "shown"],
  videoUrl: ANY,
  text: ANY
});

STATE.addState("default", {
    brandHeader: "large",
    infoPanel: "default",
    cardArtwork: "loading",
    videoPanel: "collapsed",
    buttonGroup: "hidden",
    transactionList: "hidden",
    buttonDeleteVideo: "hidden",
    buttonAddVideoMessage: "hidden",
    buttonAbortVideoUpload: "hidden",
    buttonAddVideo: "hidden",
    buttonSelectAnotherVideo: "hidden",
    buttonListTrxs: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "hidden"
  }).addState("error", {
    infoPanel: "error",
    cardArtwork: "default",
    videoPanel: "collapsed",
    buttonGroup: "hidden"
  }).addState("loading", {
    brandHeader: "large",
    infoPanel: "default",
    cardArtwork: "loading",
    videoPanel: "collapsed",
    buttonGroup: "hidden",
    transactionList: "hidden"
  }).addState("home_just_purchased", {
    brandHeader: "small",
    infoPanel: "default",
    cardArtwork: "default",
    videoPanel: "collapsed",
    transactionList: "hidden",
    buttonGroup: "shown",
    buttonAddVideoMessage: "shown",
    buttonDeleteVideo: "hidden",
    buttonAbortVideoUpload: "hidden",
    buttonAddVideo: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "hidden"
  }).addState(
  "home_just_purchased_has_video", {
    brandHeader: "small",
    infoPanel: "default",
    cardArtwork: "default",
    transactionList: "hidden",
    buttonGroup: "shown",
    buttonDeleteVideo: "shown",
    buttonAbortVideoUpload: "hidden",
    buttonAddVideoMessage: "hidden",
    buttonAddVideo: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "hidden"

  }).addState("add_video_info", {
    infoPanel: "full",
    transactionList: "hidden",
    cardArtwork: "hidden",
    buttonDeleteVideo: "hidden",
    buttonAddVideoMessage: "hidden",
    buttonAbortVideoUpload: "hidden",
    buttonAddVideo: "shown",
    buttonListTrxs: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "shown"
  }).addState(
  "uploading", {
    infoPanel: "collapsed",
    cardArtwork: "hidden",
    buttonDeleteVideo: "hidden",
    buttonAddVideoMessage: "hidden",
    buttonAbortVideoUpload: "shown",
    buttonAddVideo: "shown",
    buttonListTrxs: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "shown"
  }).addState(
  "uploaded", {
    cardArtwork: "hidden",
    buttonAddVideoMessage: "hidden",
    buttonAbortVideoUpload: "hidden",
    buttonDeleteVideo: "shown",
    buttonSelectAnotherVideo: "shown",
    buttonAddVideo: "hidden",
    buttonListTrxs: "hidden",
    buttonConfirm: "shown",
    buttonBackToHome: "shown"

  }).addState(
  "uploadError", {
    cardArtwork: "hidden",
    videoWrapper: "error",
    buttonAddVideoMessage: "hidden",
    buttonAbortVideoUpload: "hidden",
    buttonDeleteVideo: "hidden",
    buttonSelectAnotherVideo: "shown",
    buttonAddVideo: "hidden",
    buttonListTrxs: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "shown"

  }).addState(
  "confirmed_once", {
    transactionList: "hidden",
    buttonDeleteVideo: "shown",
    buttonAddVideoMessage: "hidden",
    buttonAbortVideoUpload: "hidden",
    buttonAddVideo: "shown",
    buttonListTrxs: "hidden",
    buttonConfirm: "shown",
    buttonBackToHome: "shown"
  }).addState(
  "giftee_home_has_video", {
    brandHeader: "small",
    infoPanel: "default",
    cardArtwork: "default",
    videoWrapper: "expanded",
    videoPanel: "expanded",
    buttonGroup: "hidden",
    buttonDeleteVideo: "hidden",
    buttonAddVideo: "hidden",
    buttonListTrxs: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "hidden",
    transactionList: "shown"
  }).addState(
  "giftee_home_no_video", {
    brandHeader: "small",
    infoPanel: "default",
    cardArtwork: "default",
    videoWrapper: "collapsed",
    videoPanel: "collapsed",
    buttonGroup: "shown",
    buttonDeleteVideo: "hidden",
    buttonAddVideo: "hidden",
    buttonListTrxs: "hidden",
    buttonConfirm: "hidden",
    buttonBackToHome: "hidden",
    transactionList: "shown"
  });

STATE.applyState("default");
export default STATE;