import React, {useEffect, useState} from "react";
import router, {useRouterContext} from "../router";
import API from "../api";


const CardDetails = ({card}) => {
    return <div className="brandedGiftCard">
        <p>Your card balance:</p>
        <span className="balance">{card.availBal} {card.curCode}</span>
    </div>
}

const Transaction = ({trx, i}) => {
    return <div className="trx">
        <span className="date">{trx.transactionDate}</span>
        <span className="time">{trx.transactionTime}</span>
        <span className="amount">{trx.billAmount * 0.01} {trx.currencyCode}</span>
        <span className="description">{trx.merchantName} </span>
    </div>

}
const CardStatement = ({statement}) => {
    return <div className="statement">
        <h4>Transaction list</h4>
        {
        (statement && statement.transactions) ?
            statement.transactions.map( (trx, i) => <Transaction trx={trx} key={i}/> ) :
            <p>no transactions yet!</p>
        }
    </div>
}

const BalanceCheck = () => {
    const api = API();
    const [routerState, dispatch] = useRouterContext();
    const [token, setToken] = useState("");
    const [card, setCard] = useState();
    const [statement, setStatement] = useState();

    useEffect( () => {
        setToken(routerState.route.params.token);
        api.balanceCheck(routerState.route.params.token).then(
            val => {setCard(val)}
        )
        api.cardStatement(routerState.route.params.token).then(
            val => {setStatement(val)}
        )
    },[routerState.route.params.token])

    return <>
        <div className={"contents"}>
            <h1>Hello!</h1>
            { card ? <CardDetails card={card}/> : <p>Loading your card {token} details...</p> }
            { statement ? <CardStatement statement={statement}/> : <p>Loading the transactions...</p> }
        </div>
    </>
}

export default BalanceCheck
