import API from "../api";
import {EVENTS} from "../reducer";

const api = API()

const getCodeFromQueryString = (search) => {
    const code = new URLSearchParams(search).get("code") || "";
    const match = code.match(/^[a-z0-9]{8}/)
    const accessCode = match ? match[0] : ""
    console.log("Home code:", code, "; sanitised:",accessCode,"; state:")
    return accessCode
}

const loadGiftInfo = async (code) => {
    const giftInfo = await api.getGiftInfo(code)
    const event = {type:EVENTS.GOT_GIFT_INFO, payload:giftInfo}
    return event;
}


export {getCodeFromQueryString, loadGiftInfo}