import React from "react";


class ErrorBoundary extends React.Component {
    state = {error: null};

    render() {
        if (!this.state.error) return this.props.children;
        else return <h1>Error!</h1>;
    }

    static getDerivedStateFromError(error) {
        return {error};
    }
}

export default ErrorBoundary