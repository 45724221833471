import React, {useContext, useEffect} from "react";
import {EVENTS} from "../reducer";
import {useGiftCardContext} from "../store";
import FileUpload from "../file-upload";


const AddMessage = ({onClickButton}) => {
    const [state, dispatch] = useGiftCardContext()
    const onBlurEmail = (e) => {
        const newEmail = e.target.value;
        dispatch({type:EVENTS.EMAIL_CHANGE, payload:newEmail})
    }
    const onBlurTextMsg = (e) => {
        const newTextMsg = e.target.value;
        dispatch({type:EVENTS.TEXT_MSG_CHANGE, payload:newTextMsg})
    }
    console.log("AddMessage: ", state)

    return state && state.beneficiary ?
        <>
            <div className={"contents"}>
                <p>Grab a short video with a message you'd like <b>{state.beneficiary.name} </b>
                    to watch when they receive your gift. Next, click below and select the file from
                your device. You can also add a text.</p>
                <form>
                <FileUpload />
                <textarea name="textMessage"
                          placeholder="type the message here"
                          onBlur={onBlurTextMsg}
                          defaultValue={state.textMsg}>
                </textarea>
                <input name="email" defaultValue={state.beneficiary.email} onBlur={onBlurEmail} type="email"/>
                </form>
            </div>
            <div className={"navibar"}>
                <button className="aux" data-btn="cancel" onClick={onClickButton}>another time</button>
                <button className="main" data-btn="preview" onClick={onClickButton}>preview</button>
            </div>
        </> : <p>missing data</p>
}
export default AddMessage;
