import {useGiftCardContext} from "./store";
import AddMessage from "./screens/add-message";
import PreviewMessage from "./screens/preview-message";
import Home from "./screens/home";
import React, {useEffect, useRef} from 'react'
import {EVENTS} from "./reducer";
import Accepted from "./screens/accepted";

const SCREENS = [Home, AddMessage, PreviewMessage, Accepted]
const resolveStepFromState = (state) => {
    if (!state) return 0;
    if (!state.accessCode) return 0;
    if (!state.beneficiary) return 0;
    if (!state.addMessageButtonClicked) return 0;
    if (!state.previewButtonClicked) return 1;
    if (!state.acceptButtonClicked) return 2;
    return 3;
}
const MessageEditor = () => {

    const [state, dispatch] = useGiftCardContext()
    const stepIndex = resolveStepFromState(state)
    const activeScreenRef = useRef(null);

    useEffect(() => {
        if (activeScreenRef.current) {
            console.log("scrolling to ",activeScreenRef.current)
            activeScreenRef.current.scrollIntoView({behavior: 'smooth'});
        } else {
            console.log("Not scrolling... why? ", activeScreenRef.current)
        }
    }, [stepIndex, state]);

    const onClick = (e) => {
        console.log('onClick ', e.target.dataset.btn);
        const buttonType = e.target.dataset.btn
        switch (buttonType) {
            case 'cancel':              dispatch({type: EVENTS.RESET, payload: true}); break;
            case 'addMessage':          dispatch({type: EVENTS.CLICKED_ADD_MESSAGE, payload: true}); break;
            case 'preview':             dispatch({type: EVENTS.CLICKED_PREVIEW, payload: true}); break;
            case 'backToAddMessage':    dispatch({type: EVENTS.RESET, payload: true}); break;
            case 'accept':              dispatch({type: EVENTS.ACCEPT, payload: true}); break;
            default: throw new Error("unknown button type: " + buttonType)
        }
    }

    console.log("MessageEditor:", state)

    return <>
        <div className="step">{stepIndex+1}/{SCREENS.length}</div>
        <div className="screen">
        {SCREENS.map((ScreenClass, i) => {
            const isActive = i === stepIndex;
            return <div key={i} className={isActive ? "active" : "inactive"} ref={isActive ? activeScreenRef : undefined}>
                <ScreenClass onClickButton={onClick}/>
            </div>
        })}
    </div></>
}

export default MessageEditor