import React, {forwardRef, useRef} from "react";
import API from "./api";
import "./file-upload.css"
import {Button} from "./buttons";
import {useRouterContext} from "./router";

export const FileUploadButton = forwardRef(({
                                              label,
                                              onFileSelected,
                                              onUploadStart,
                                              onProgress,
                                              onFileUploaded,
                                              onError,
                                              className
                                            }, ref) => {
  const hiddenFileInput = useRef(null);
  const api = API();
  const [routerState, _] = useRouterContext();

  const handleClick = (event) => {
    if (event.target === hiddenFileInput.current) {
      console.log("click")
    } else {
      hiddenFileInput.current.click();
    }
  };

  const handleProgress = (e) => {
    const p = Math.floor(e * 100);
    if (onProgress) onProgress(p);
  }

  const handleFileSelected = (fileSelected) => {
    if (onFileSelected) onFileSelected(fileSelected);
  }

  const handleUploadStart = (xhr) => {
    if(onUploadStart) onUploadStart(xhr);
  }


  const handleChange = async (event) => {
    const fileSelected = event.target.files[0];
    try {
      handleFileSelected(fileSelected);
    } catch (e) {
      console.error(e);
      if (onError) onError(e);
    }
    try {
      const token = routerState.route.params.token;
      const response = await api.uploadFiles(fileSelected, token, handleProgress, handleUploadStart);
      console.log(response);
      if (response && response.status && response.status !== 200) throw new Error("Upload error");
      console.log("upload completed", fileSelected);
      handleProgress(1);
      if (onFileUploaded) onFileUploaded(response);
    } catch (e) {
      if(e.message !== "File upload aborted") {
        console.error(e);
        if (onError) onError(e);
      }
      handleProgress(0);
    }
  };

  return (
    <Button label={label} onClick={handleClick} className={className} ref={ref}>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        accept="video/mp4,video/x-m4v,video/*"
        style={{display: "none"}}
      />
    </Button>
  );
});

export default FileUploadButton