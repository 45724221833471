import React, {forwardRef} from "react";
import "./buttons.css"

const ButtonGroup = forwardRef((props, ref) => {
  return <div className={`buttonGroup ${props.className}`} ref={ref}>{props.children}</div>
})

const Button = forwardRef(({
                             label='click',
                             onClick = () => {},
                             className='',
                             children
                           }, ref) => {
  return <div className={`${className} button`} onClick={onClick} ref={ref}>{label}{children}</div>
})

export {Button, ButtonGroup}