import React, {createContext, useContext, useReducer} from "react";
import Reducer from './reducer'

const initialState = {
    accessCode:"",
    beneficiary:"",
    sender:"",
    textMsg:"",
    videoUrl:""
};

const GiftCardContext = createContext(initialState);

const GiftCardProvider = ( {children} ) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <GiftCardContext.Provider value={[ state, dispatch ]}>
            {children}
        </GiftCardContext.Provider>
    );
}


const useGiftCardContext = () => {
    let stateAndDispatch = useContext(GiftCardContext)
    const dispatchFn = (...args) => {
        console.log("dispatchFn", ...args)
    }
    if (! (Symbol.iterator in Object(stateAndDispatch))) {
        stateAndDispatch = [stateAndDispatch, dispatchFn]
    }
    return stateAndDispatch
}

export {useGiftCardContext}
export default GiftCardProvider;